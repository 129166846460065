import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { AftcMembershipDetails } from "@/model/membershipDetails/aftc-membershipDetails";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { EncryptionService } from "@services/encryption.service";
import * as XLSX from "xlsx/xlsx.mjs";
import * as FileSaver from 'file-saver';
import { MembershipDetailsExcel } from "@/model/excel-details/membership-details-excel";

@Component({
  selector: "app-aftc-membership-view",
  templateUrl: "./aftc-membership-view.component.html",
  styleUrls: ["./aftc-membership-view.component.scss"]
})
export class AftcMembershipView implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText;
  allApplicationDetails: AftcMembershipDetails[] = [];
  getApplicationDetails: AftcMembershipDetails[] = [];
  getApplicantionDetails: AftcMembershipDetails[] = []
  presentId: number | null = null;
  localdata: AftcMembershipDetails[] = [];
  registrionDate: string[] = [];
  dynamicValues: any;
  isModalShow: boolean = false;
  aftcShow: boolean = false;
  onlineShow: boolean = true;
  paymentTypeFilter: string | null;
  registrionDateFilter: string | null;
  fileType: string = "";
  excelGenerateSheet: MembershipDetailsExcel[] = [];
  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toaster: ToastrService,
    private datePipe: DatePipe, private encryptionService: EncryptionService) {

  }

  ngOnInit() {
    this.getAllApplicationDetails()
  }

  ngOnDestroy() {
  }

  // Function to get all application details
  getAllApplicationDetails() {
    this.apiServices.getAllApplication()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getApplicationDetails = data.sort((a, b) => b.membershipDetailsId - a.membershipDetailsId)
        // .filter(item => item.paymentStatus !== "");
        this.allApplicationDetails = this.getApplicationDetails;
        const regDate = new Set(this.allApplicationDetails.map(item => item.registeredDate))
        this.registrionDate = Array.from(regDate).filter(item => item !== "");
        console.log("testing::", this.allApplicationDetails)
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.toaster.error("Something went wrong , Please try again later....")
      })
  }

  //Function is used to select a member based on the provided Id and update the isUpdate status of the member
  selectMember(item: AftcMembershipDetails, Id: number) {
    if (this.presentId == Id) {
      this.presentId = null
      item.isUpdate = false
    } else {
      this.presentId = null
      this.presentId = Id
      for (let sta of this.allApplicationDetails) {
        sta.isUpdate = false;
        if (sta.membershipDetailsId == Id) {
          sta.isUpdate = true;
        }
      }
    }
  }





  // Function updates the approval status of the selected member.
  updateApproval() {
    this.spinner.show()
    const jsonData = {
      membershipDetailsId: this.presentId,
      status: "Approved"
    }
    this.apiServices.updateApprove(jsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide()
        this.presentId = null;
        this.getAllApplicationDetails();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong , Please try again later....")
        console.log("Something went wrong :", err);
      })
  }

  //Function triggers a confirmation dialog and sends an email if confirmed
  sendEmail(id: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to sent the receipt!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sent it!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.mailSendFunction(id)
      }
    });
  }

  //Function triggers a confirmation dialog and sends an Whatsapp if confirmed
  sendWhatsapp(aftc: AftcMembershipDetails) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to send the WhatsApp Message",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sent it!"
    }).then((result) => {
      if (result.isConfirmed) {
        if (aftc.city == '') {
          this.whatsappMassageSentFunction(aftc.membershipDetailsId, true, aftc.paymentType)
        } else {
          this.whatsappMassageSentFunction(aftc.membershipDetailsId, false, aftc.paymentType)
        }
      }
    });
  }

  //Function triggers a confirmation dialog and the download form if confirmed
  downloadFile(id: number) {
    const memberId = id
    for (let mem of this.getApplicationDetails) {
      if (id == mem.membershipDetailsId) {
        this.localdata.push(mem)
      }
    }
    this.captureScreen()
  }


  // Function extracts unique forum names from a string of forums separated by #.
  getUnique(forums: string) {
    const uniqueNames = new Set<string>();
    const forumArray = forums.split('#').map(item => item.trim());
    forumArray.forEach(forum => {
      if (forum) {
        uniqueNames.add(forum);
      }
    });
    return Array.from(uniqueNames).join(', ');
  }

  //Function captures the screen content and generates a PDF with membership registration details
  //   captureScreen() {
  //     const invoiceHtml = `<!DOCTYPE html>
  //   <html>
  //   <head>
  //   <title>Page Title</title>
  //   </head>
  //   <style>
  //   .heading{
  //   text-align: center;
  //   text-decoration: underline;
  //   text-decoration-thickness: 5px;  
  //   }
  //   .text1{
  //   font-size:28px;
  //   margin-left: 40px;
  //   }
  //   .header{
  //     border: 2px solid #DDDDDD;
  //     text-align: left;
  //     text-align: center;
  //     background-color:rgba(208,255,148,1)!important;
  //     transform: translate3d(0, 0, 0);
  //     border-top-right-radius: 20px;
  //     border-bottom-right-radius: 20px;
  //     border-top-left-radius: 20px;
  //     border-bottom-left-radius: 20px;
  //   }
  //   .table-container {
  //     justify-content: center;
  //   }
  //   table {
  //     width: 100%;
  //     margin-left: 110px;
  //     font-size:28px;
  //   }
  //   td, th {
  //     border: 1px solid #DDDDDD;
  //     text-align: left;
  //     padding: 8px 30px;
  //     font-size:28px;
  //   }
  //   th {
  //     text-align: center;
  //     font-size:28px;
  //   }
  //   .footer{
  //   marign-top-10px
  //   }
  //   .sign {
  //       display: flex;
  //       justify-content: space-between;
  //       margin-bottom: 25px;
  //       margin-top: 120px;
  //   }
  //   .dateType {
  //     border-bottom: 3px dotted #111;
  //   }

  //   img{
  //   width: 100%
  //   }
  //   </style>
  //   <body>
  //     <div class="container" >
  //            <img src="../../../../assets/img/Agrofood-chamber.jpg" alt="Italian Trulli">
  //       <div class="header">
  //          <!--<div class="subject">
  //              <h2 class="mail1">Aftc Membership Registration Successful</h2>
  //          </div>-->
  //           <div class="footer"></div>
  //          </div>
  //           <div class="row col-md-9 table-container" style="margin-top: 20px;">
  //                   <div class="col-md-12">
  //                       <table>
  //                           <thead>
  //                           <tr>
  //                               <th colspan="2" >Membership Registrion Details</th>
  //                           </tr>
  //                           </thead>
  //                           <tbody *ngIf="let mem of localdata">
  //                           <tr>
  //                               <td>Applicant's Name</td>
  //                               <td>{{applicantName}}</td>
  //                           </tr>
  //                           <tr>
  //                               <td>Type of Organization</td>
  //                               <td>{{applicantRole}}</td>
  //                           </tr>
  //                           <tr>
  //                               <td>Organization Name</td>
  //                               <td>{{organizationName}}</td>
  //                           </tr>
  //                           <tr>
  //                               <td>Type of Business</td>
  //                               <td>{{businessType}}</td>
  //                           </tr>
  //                           <tr>
  //                               <td>Nature of Business</td>
  //                               <td>{{businessName}}</td>
  //                           </tr>
  //                           <tr>
  //                               <td>Whatsapp Number</td>
  //                               <td>{{whatsappNo}}</td>
  //                           </tr>
  //                           </tbody>
  //   </table>
  //   </div>
  //   </div>
  //   <br><br><br>
  //   <h2 class="heading"><u>Office Note</u></h2>
  //   <br>
  //   <p class="text1">{{membershipPeroid}} Membership Subscription of Rs <span class="dateType">{{memershipAmount}}</span> and Admission Fee of <br>
  //   Rs<span class="dateType"> {{adminAmount}} </span>.collected and issued Receipt No <span class="dateType"> {{applicantNo}} </span> dt <span class="dateType"> {{tranctionDate}} </span><br>
  //    Membership approved by the Chamber Executive Committee on {{payDate}}.</p>
  //    <p>
  //  </p>
  //  <br>
  //  <br>
  //   <div class="sign">
  //       <div class="left-div">
  //           <h3 style="margin-left:10px">Secretary</h3>
  //       </div>
  //       <div class="right-div" style="margin-right:50px">
  //           <h3>Authorized Person</h3>
  //       </div>
  //   </div>
  //   <div class="" style=" margin-left: 10px;margin-top: 0px;font-weight: bold">
  //               <h4><b>Thanks,<br>
  //               Admin<br>
  //               Agro Food Chamber</b> </h4>
  //           </div>
  //       </div>
  //   <footer style="text-align: center;margin-top: 20px;color: #666;"></footer>
  //   </body>
  //   </html>

  //   `;
  //     for (let mem of this.localdata) {
  //       if (mem.memberPeriod == 'Long Term Membership') {
  //         var membership = 'Long'
  //         var amount = '10,000'
  //       } else {
  //         var membership = 'Annual'
  //         var amount = '500'
  //       }
  //       if (mem.admissionFee == 'Admission Fee') {
  //         var adminFee = '1000'
  //       } else {
  //         var adminFee = '--'
  //       }
  //       const trandate: string = mem.registeredDate;
  //       const formattedDate: string = trandate.replace(/IST/, '+05:30');
  //       const epochTimestamp = mem.transactionDetails.createdAt;
  //       const epochTimestampInMilliseconds = mem.transactionDetails.createdAt;
  //       const paydate = new Date(epochTimestampInMilliseconds);
  //       const formattedPayDate = paydate.toLocaleDateString('en-IN', {
  //         day: '2-digit',
  //         month: '2-digit',
  //         year: 'numeric',
  //       });

  //       this.dynamicValues = {
  //         applicantName: mem.applicantName,
  //         applicantRole: mem.orgType,
  //         organizationName: mem.organizationName,
  //         businessType: mem.businessType,
  //         businessName: mem.businessName,
  //         whatsappNo: mem.whatsappNo,
  //         membershipPeroid: membership,
  //         memershipAmount: amount,
  //         tranctionDate: this.datePipe.transform(formattedDate, 'dd/MM/yyyy'),
  //         payDate: formattedPayDate,
  //         adminAmount: adminFee,
  //         applicantNo: mem.membershipDetailsId,
  //       };
  //     }
  //     const replacedHtml = invoiceHtml.replace(/{{(.*?)}}/g, (_, match) => {
  //       const key = match.trim();
  //       return this.dynamicValues[key] || '';
  //     });

  //     const hiddenElement = document.createElement('div');
  //     hiddenElement.innerHTML = replacedHtml;
  //     document.body.appendChild(hiddenElement);

  //     html2canvas(hiddenElement).then(canvas => {
  //       const imgWidth = 208;
  //       const pageHeight = 295;
  //       const imgHeight = canvas.height * imgWidth / canvas.width;
  //       const contentDataURL = canvas.toDataURL('image/png');
  //       const pdf = new jspdf('p', 'mm', 'a4');
  //       const position = 0;
  //       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //       pdf.save('Membership.pdf');
  //       document.body.removeChild(hiddenElement);
  //     });
  //   }


  captureScreen() {
    const invoiceHtml = `


    <!DOCTYPE html>
      <html>
      <head>
      <title>Page Title</title>
      </head>
      <style>
      .text1{
      font-size:28px;
      margin-left: 40px;
      }
      .header{
        border: 2px solid #DDDDDD;
        text-align: left;
        text-align: center;
        background-color:rgba(208,255,148,1)!important;
        transform: translate3d(0, 0, 0);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      .footer{
      marign-top-10px
      }
      .sign {
          display: flex;
          justify-content: space-between;
          margin-top:30px;
      }
    .cutomerText{
    display: flex;
    margin-top:30px;
    }
    
      img{
      width: 100%;
      margin-top:30px;
      }
      
     .dateType {
        border-bottom: 3px dotted #111;
        text-align: center;
        font-weight: bold;
       }
      
      </style>
      
      <body>
        <div class="container" >
                   <img src="../../../../assets/img/Agrofood-chamber.jpg" alt="Italian Trulli">
          <div class="header">
              <div class="footer"></div>
             </div>
    
    
      <div class="sign">
          <div class="left-div">
              <h3 style="margin-left:10px">No. 2022-23/{{applicantNo}}</h3>
          </div>
          <div class="center-div">
              <h3 style="margin-left:10px;background-color:darkred;color:white">&nbsp; RECEIPT for CASH / CHEQUE &nbsp;</h3>
          </div>
          <div class="right-div" style="margin-right:50px">
              <h3>தேதி <span class="dateType" style="width:20px">{{paymentDate}}</span></h3>
          </div>
      </div>
        <div class="cutomerText">
          <div class="">
              <h3 >MS </h3>
          </div> <h3 class="dateType" style="width:70%">{{applicantName}}</h3>
          <div class="" style="margin-left:20px">
              <h3>அவர்களிடமிருந்து</h3>
          </div>
      </div>
      <div class="cutomerText">
     <h3 class="dateType" style="width:73%">{{membershipPeroid}}</h3>
          <div class="" style="margin-left:20px">
              <h3>வகைக்காக</h3>
          </div>
      </div>
       <div class="cutomerText">
          <div class="">
              <h3>ரூபாய் </h3>
          </div> <h3 class="dateType" style="width:65%">{{memershipAmount}}</h3>
          <div class="" style="margin-left:20px">
              <h3>மட்டும்</h3>
          </div>
      </div>
       <div class="cutomerText">
          <div class="">
              <h3 class="sign">ரொக்கம் / காசோலை</h3>
          </div> <h3 class="dateType" style="width:40%">{{paymentType}}</h3>
          <div class="" style="margin-left:10px;margin-top:28px;">
              <h3>மூலம் பெற்றுக் கொண்டேன்.</h3>
          </div>
      </div>
      <div class="sign" style="margin-top:80px">
      <div class="left-div">
      <div class="text-center" style="">
      <h1 style="border: 2px solid #000000;">
      <span style="background-color:darkred;height:100%;color:white">&nbsp;Rs &nbsp;</span>&nbsp; {{memershipAmount}} &nbsp;</h1>
    </div>
</div>
      <div class="center-div">
        <h3>பணம் செலுத்தியவர்</h3>
      </div>
      <div class="right-div">
        <h3>வசூலிப்பவர்</h3>
      </div>
    </div>
      <footer style="text-align: center;margin-top: 20px;color: #666;"></footer>
      </body>
      </html>
    
  `;
    for (let mem of this.localdata) {
      if (mem.memberPeriod == 'Long Term Membership') {
        var membership = 'Long'
        var amount = mem.transactionDetails.amount
      } else {
        var membership = 'Annual'
        var amount = mem.transactionDetails.amount
      }
      if (mem.admissionFee == 'Admission Fee') {
        var adminFee = '1000'
      } else {
        var adminFee = '--'
      }
      const trandate: string = mem.registeredDate;
      const formattedDate: string = trandate.replace(/IST/, '+05:30');
      const epochTimestamp = mem.transactionDetails.createdAt;
      const epochTimestampInMilliseconds = mem.transactionDetails.createdAt;
      const paydate = new Date(epochTimestampInMilliseconds);
      const formattedPayDate = paydate.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      var applicantNo: any = "";

      if (mem.paymentType == "AFTC") {
        applicantNo = mem.manualReceiptNumber
      } else {
        applicantNo = mem.membershipDetailsId
      }


      this.dynamicValues = {
        applicantName: mem.applicantName,
        applicantRole: mem.orgType,
        organizationName: mem.organizationName,
        businessType: mem.businessType,
        businessName: mem.businessName,
        whatsappNo: mem.whatsappNo,
        membershipPeroid: membership,
        memershipAmount: amount,
        tranctionDate: this.datePipe.transform(formattedDate, 'dd/MM/yyyy'),
        payDate: formattedPayDate,
        adminAmount: adminFee,
        applicantNo: applicantNo,
        paymentType: mem.paymentType,
        paymentDate: mem.registeredDate,
      };
    }
    const replacedHtml = invoiceHtml.replace(/{{(.*?)}}/g, (_, match) => {
      const key = match.trim();
      return this.dynamicValues[key] || '';
    });

    const hiddenElement = document.createElement('div');
    hiddenElement.innerHTML = replacedHtml;
    document.body.appendChild(hiddenElement);

    html2canvas(hiddenElement).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('Membership.pdf');
      document.body.removeChild(hiddenElement);
    });
  }

  //Function sends an approval email for Memebser
  mailSendFunction(id: number) {
    this.spinner.show()
    this.apiServices.sendApproveMail(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        this.toaster.success("The email has been successfully sent")
      }, (error: any) => {
        this.spinner.hide()
        console.log("something went wrong")
        this.toaster.error("The email has been Not sent")
      })

  }

  //Function sends a WhatsApp message for memebser

  whatsappMassageSentFunction(id: number, isSend: Boolean, paymntType: String) {
    this.spinner.show()
    this.apiServices.sendWhatsapp(id, isSend, paymntType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        this.toaster.success("The WhatsApp has been successfully sent")
      }, (error: any) => {
        this.spinner.hide()
        console.log("something went wrong")
        this.toaster.error("The WhatsApp has been Not sent")
      })

  }

  //Function opens a modal and sets the applicant details based on the given ID.

  openModal(id: number) {
    this.isModalShow = true;
    var localDetails: AftcMembershipDetails[] = [];
    for (let member of this.getApplicationDetails) {
      if (id == member.membershipDetailsId) {
        localDetails.push(member);
      }
    }
    this.getApplicantionDetails = localDetails;
    console.log("getDetails::", this.getApplicantionDetails)
  }

  newEntry() {
    const value = 'AFTCCHAMBER';
    const encryptedValue = this.encryptionService.encrypt(value);
    localStorage.setItem('paymentType', encryptedValue);
    this.router.navigate(['/chamberregistration'])
  }

  mutipleFilter(event: any, filterType: any) {
    const value = event.target.value;
    switch (filterType) {
      case "payment":
        this.paymentTypeFilter = value !== "Select Any One" ? value : null;
        break;
      case "date":
        this.registrionDateFilter = value !== "Select Date" ? value : null;
        break;
      default:
        break;
    }
    this.applyFilters();
  }

  // Function filters the applications based on the selected date

  applyFilters() {
    const filterValue = this.paymentTypeFilter ? this.getApplicationDetails.filter(item => {
      if (this.paymentTypeFilter === 'AFTC') {
        this.aftcShow = true;
        this.onlineShow = false;
        return item.paymentType === 'AFTC';
      } else if (this.paymentTypeFilter === 'ONLINE') {
        this.aftcShow = false;
        this.onlineShow = true;
        return item.paymentType === 'ONLINE'
      }
      return true;
    }) : this.getApplicantionDetails;
    const dateFiltered = this.registrionDateFilter ? filterValue.filter(item => item.registeredDate === this.registrionDateFilter) : filterValue;

    this.allApplicationDetails = dateFiltered;
  }

  dateFilter(event: any) {
    const values = event.target.value;
    const dates = this.datePipe.transform(values, 'dd-MM-yyyy');
    console.log(dates)
    let dateFilterValues: AftcMembershipDetails[] = []
    for (let date of this.getApplicationDetails) {
      if (dates === date.createdAt) {
        dateFilterValues.push(date);
      }
    }
    this.allApplicationDetails = dateFilterValues;
    console.log(dateFilterValues)
  }


  photoView(id: number, fileName: string) {
    let filePath: string = "";
    for (let details of this.allApplicationDetails) {
      if (id == details.membershipDetailsId) {
        if (fileName == "manual_form") {
          filePath = details.manualImagePath;
        } else if (fileName == "udyam") {
          filePath = details.udyamCertificatePath;
        }

        let fileType: string = "";
        if (filePath.includes("pdf")) {
          fileType = "application/pdf";
        } else if (filePath.includes("jpg") || filePath.includes("jpeg") ||
          filePath.includes("png")) {
          fileType = "image/jpg";
        }
        this.fileType = fileType;
      }
    }
    this.spinner.show();
    this.apiServices.fileGet(id, fileName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("getImage::", data)
        const mimeType = this.fileType;
        const myBlob = new Blob([data], { type: mimeType });
        window.open(URL.createObjectURL(myBlob), "_blank");
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something Went Wrong or No Document Upload");
      });
  }

  generateXLSX() {
    this.spinner.show();
    if (this.allApplicationDetails.length > 0) {
      this.excelGenerateSheet = this.allApplicationDetails.map((element, index) => {
        let excelValue = new MembershipDetailsExcel();
        excelValue.ApplicantName = element.applicantName;
        excelValue.WhatsappNo = element.whatsappNo;
        excelValue.MembershipType = element.memberPeriod;
        excelValue.PaymentType = element.transactionDetails.paymentMode;
        excelValue.PaymentStatus = element.paymentStatus;
        excelValue.TransactionID = element.transactionDetails.bankTxnId;

        // Convert 'createdAt' into a valid Date object if necessary
        let transactionDate: Date;
        if (typeof element.createdAt === 'string') {
          const parts = element.createdAt.split('-');
          // Assuming the string is in the format dd-MM-yyyy
          transactionDate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
        } else {
          transactionDate = new Date(element.createdAt);
        }

        // Only transform if it's a valid date
        if (transactionDate instanceof Date && !isNaN(transactionDate.getTime())) {
          const formattedDate = this.datePipe.transform(transactionDate, 'dd-MM-yyyy');
          excelValue.TransactionDate = formattedDate;
        } else {
          excelValue.TransactionDate = 'Invalid Date';
        }

        return Object.assign({}, excelValue);
      });

      console.log(this.excelGenerateSheet);

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.excelGenerateSheet);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, "Membership_details");
    } else {
      this.spinner.hide();
      this.toaster.error("No membership details are available to generate Excel.");
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, fileName);

    this.spinner.hide();
    this.toaster.success("The file was downloaded successfully.");
  }
}

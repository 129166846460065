<app-toll-gate-navbar *ngIf="userType == 'ADMIN'"></app-toll-gate-navbar>
<div class="container -fulid">
  <div *ngIf="userType == 'ADMIN'" class="row"></div>
  <div class="row mt-5">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-3">
      </div>
    </div>
    <div class="container">
      <div class="table-responsive">
        <table class="table">
          <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>IRM Number</th>
            <th>Total Bill Amount (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
            </svg>)</th>
            <th>Bill Date</th>
            <th>Received Amount (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
            </svg>)</th>
            <th>Cash Deposit</th>
            <th>Invoice Print</th>
          </tr>
          </thead>
          <tbody *ngFor="let aftc of this.getCashDetails | filter: searchText;let i = index">
          <tr class="text-center">
            <td>{{i + 1}}</td>
            <td>{{aftc.cirm}}</td>
            <td>{{formatCharges(aftc.billAmount)}}</td>
            <td>{{aftc.createdAt | date : "dd/MM/yyyy"}}</td>
            <td><input class="form-control" type="text" *ngIf="aftc.receivedAmount == ''" [(ngModel)]="aftc.amount"
                       (keypress)="OnlyNumbers($event)" (input)="recivedAmount(aftc)">
              <input class="form-control" type="text" [value]="formatCharges(aftc.receivedAmount)"
                     *ngIf="aftc.receivedAmount != ''" readonly>
            </td>
            <td><label *ngIf="aftc.status == 'Approved'">Received</label>
              <button *ngIf="aftc.status == '' || aftc.status == 'Not Approved'" class="btn btn-success" type="button"
                      [disabled]="!aftc.amount || isShowCash" (click)="updateStatus(aftc.cashIrmId)">Collect
              </button>
            </td>
            <td><i title="Print" class="fas fa-print" (click)="printInvoice(aftc.cirm)"></i></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div id="page">
    <div id="container">
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div id="ring"></div>
      <div class="fw-bolder" id="h3"><img src="../assets/img/AFTC logo.png" class="logo" alt="Sample image"></div>
    </div>
  </div>
</ngx-spinner>
